:root{
  --tiles-unit-multi: 1;
  --main-color: rgba(255, 255, 255, 0.5);
  --bg-color:#0b0d19;
  --accent-color: #4a5fe1;
  --accent-color2: rgb(255, 229, 82);
  --accent-color3: #00b0b6;
  --light-accent-color: #910909;
  /*#B23B3B;*/
}

/*
#root, main, #main {
  height: 98vh;
}

.content {
  background: rgb(255, 255, 255);
  height: calc(98vh - calc(var(--tiles-unit-multi)*15vmax));
}

#tile156, #tile194, #tile232, #tile280, #tile318, #tile356, #tile394, #tile432, #tile480{
  border-right: thin solid white;
}
*/
section#main  {
  /*
  border-right: 1vmax solid white;
  border-left: 1vmax solid white;
  */
 }
.border {
  width: 100%;
  border-top: 1vh solid white;
  z-index: 84;
  position: fixed;
}
.tiles + .border {
  bottom: 0;
  display: none;
}
#lg-download-1 {
  display: none;
}
.author {
  position: absolute;
  top: calc(var(--tiles-unit-multi)*2.5vmax);
  color: white;
  left: calc(var(--tiles-unit-multi)*4.5vmax);
  z-index: 42;
  text-transform: lowercase;
  font-size: 0.7em;
  transform-origin: left;
  border-bottom: thin solid #ffffff57;
  padding-bottom: 0.1em;
}
.photo {
  width: 3.5vmax;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(-0deg) translate(12.5vh, 0);
  border-bottom: 1.2vh solid white;
  border-right: 0.5vh solid white;
  border-left: 0.5vh solid white;
  border-top: 0.5vh solid white;
  transition: all ease-out 0.25s;
  z-index: -1;
  opacity: 1;
}
.photo:active, .contact-card > header ul li:first-child:hover > .photo {
  transform: rotate(-7deg) scale(0.8) translate(6vh, -2vh);
  width: 4vmax;
  right: 1vmax;
  transform-origin: right;
}

.contact-card header em:after {
  content: "leomenant@gmail.com";
  position: absolute;
  font-size: 0.8em;
  display: block;
  margin-top: 0.1vh;
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  font-style: normal;
  color: darkgray;
}
section.contact-card {
  position: fixed;
  top: 1vh;
  right: 1vh;
  z-index: 84;
  transform-origin: right;
  width: 4vmax;
  height: 4vmax;
}
.contact-card header {
  color: white;
  text-align: left;
  line-height: 1em;
  width: 18vmax;
  margin: 0vh 1vmax 0 0;
}
.contact-card header span {
  display: block;
}
hr {
  height: 1px;
  border-bottom: thin solid #fff0;
  border-right: thin solid #fff0;
  border-left: thin solid #fff0;
  margin: 1em 0;
}
.contact-card header em {
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.75);
}
.contact-card header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  /* border: 0.15em solid white; */
  z-index: 42;
  width: 4vmax;
  min-width: 40px;
  height: 8vmax;
}
.contact-card header ul li {
  width: 100%;
  transition: transform ease 1.5s;
}
.contact-card header ul li a {
  display: block;
}
.contact-card header ul li a:hover > svg > path {
  animation: fillColors2 linear 1s infinite;
}
.contact-card header ul li a:hover > svg {
  transform: scale(1.1) rotate(1deg);
}
.social-links svg {
  width: 40%;
  padding: 30%;
}
.social-links path {
  fill: white;
}
.mailto:before {
  content: "mail ▷";
  position: absolute;
  color: white;
  left: -4vmax;
  top: 3vh;
  font-size: 0.8em;
  font-variant: small-caps;
  transform: rotate(-5deg);
  opacity: 0;
  transition: opacity ease 0.2s;
}
.linkedin:before {
  content: "cv \25B7";
  position: absolute;
  color: white;
  left: -2vmax;
  top: 5vmax;
  font-size: 0.8em;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  transform: rotate(5deg);
  opacity: 0;
  transition: opacity ease 0.2s;
}
.mailto:hover:before, .linkedin:hover:before {
  opacity: 1;
}
svg.cube-svg .face:nth-of-type(2) {
  fill:  rgba(30, 40, 103, 1);
}
svg.cube-svg .face:nth-of-type(3) {
  fill: rgb(23, 69, 121)
}
.App {
  text-align: center;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: calc(var(--tiles-unit-multi)*40vmax);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + calc(var(--tiles-unit-multi)*2vmax));
  color: var(--main-color);
}
.space{
  height: calc(var(--tiles-unit-multi)*7vmax);
  min-height: 15vh;
}
.space > div{
  position: fixed !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}
.home {
  width: calc(100% - 195px);
  right: 0;
  position: absolute;
}
.about, .portfolio, .contact {
  margin-left: 10.5vmax ;
  font-family: 'rubik';
  font-size: calc(10px + calc(var(--tiles-unit-multi)*0.5vmax));
  line-height: 2em;
  font-weight: 100;
  color: #bbb;
  z-index: 42;
}
.about .tab .tab-label{
  z-index: 42;
  position: relative;
}
.about li {
  list-style: none;
}
.about ul.hidden {
  display: none;
}
.about ul li span {
  display: block;
  font-size: 0.5em;
  line-height: 0.25em;
  color: var(--accent-color);
  font-family: 'eurocine';
}
.about .tab-content ul {
  flex-grow: 1;
  flex-basis: 33%;
}
.about ul.career > li span {
  color: var(--accent-color);
  position: relative;
  z-index: 42;
}
.about ul.school > li span {
  color: var(--accent-color3);
}
.portfolioWrapper {
  display: inline-flex;
  flex-flow: wrap;
  justify-content: left;
  gap: 0;
  margin: 0 0 0 195px;
  width: calc(calc(100% - 195px) - 15vmax);
}
.portfolioWrapper article {
  transition: all 0.2s ease-out;
  z-index: 42;
  width: 31%;
  margin: 0 1%;
  background: rgba(0, 0, 0, 1);
}
.lg-react-element > .gallery-item:not(:nth-child(1)) {
  display: none;
}
.lg-react-element {
  min-height: 35vh;
  overflow: hidden;
}
#lg-download-2 {
  display: none;
}
article.PortfolioItem p {
  font-size: 0.8em;
  width: calc(100% - 2vmax);
  padding: 1vmax;
  display: block;
  font-family: "eurocine";
  line-height: 1.6em;
  background-color: white;
  color: black;
}
span.project > a {
  padding: 1em;
  transition: all ease-out 0.3s;
  display: inline-block;
  transform-origin: left;
  transform: scale(0.8) translateX(-3vmax);
  opacity: 0;
  color: white;
  position: relative;
  z-index: 42;
  background: black;
}
.portfolioWrapper span.project {
  text-transform: uppercase;
  font-size: 0.5em;
  margin: 18vh 0 0 0;
  display: inline-block;
  position: absolute;
}
.portfolio h2{
  font-weight: lighter;
}
.portfolio p{
  font-weight: light;
  font-size: 0.5em;
}
.portfolioWrapper article{
  flex: none, 1 1, 20%;
  z-index: 42;
}
.portfolioWrapper article img {
  max-height: 40vh;
  filter: grayscale(1);
  min-width: 100%;
  height: auto;
  opacity: 0.5;
  transition: all ease-out 0.2s;
  transform: scale(0.9);
  cursor: pointer;
}
.portfolioWrapper article:hover div a > img, .portfolioWrapper article.active div a > img {
  filter: grayscale(0.15);
  opacity: 1;
  transform: scale(1);
}
article > *{
  color: var(--main-color);
}
article h3 {
  font-weight: 500;
  color: white;
  transition: all ease-out 0.3s;
  transform: scale(0.9);
  padding: 1em;
  display: inline-block;
  z-index: 42;
  position: relative;
  font-size: 1.4em;
  margin: 0 0 0.2vh 0.25vmax;
  border: 0.1em solid white;
}
.portfolioWrapper article:hover span.project > h3, .portfolioWrapper article.active span.project > h3 {
  transform: scale(1.2);
  border: 0;
}
.portfolioWrapper article:hover span.project > a, .portfolioWrapper article.active span.project > a {
  transform: scale(1.3) translate(0vmax, -0.1vh);
  opacity: 1;
}
.baseline span, footer span, nav{
  color: rgba(255, 255, 255, 0.9);
  display: block;
  text-align: left;
  font-family: 'rubik';
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  text-transform: lowercase;
  font-weight: 100;
  font-size: calc(var(--tiles-unit-multi)*1em);
}
button {
  border: none;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  transition: background 0.25ms ease-in-out, opacity 0.15ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button:active {
  opacity: 0.8;
}

/* Animations */

@keyframes appear {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fillColor {
  from {
    fill: rgba(255, 255, 255, 0);
  }
  to {
    fill: var(--main-color);
  }
}

@keyframes drawLM {
  from{
    stroke-dashoffset: calc(var(--tiles-unit-multi)*136vmax);
  }
  to{
    stroke-dashoffset: calc(var(--tiles-unit-multi)*246vmax);
  }
}

@keyframes cube1 {
  from{
    transform: initial;
  }
  to{
    transform: translate(0.9%, -30%);
  }
}

@keyframes cube2 {
  from{
    transform: initial;
  }
  to{
    transform: translate(15%, 30%);
  }
}

@keyframes cube3 {
  from{
    transform: initial;
  }
  to{
    transform: translate(-13.5%, -5%);
  }
}

@keyframes cube4 {
  from{
    transform: initial;
  }
  to{
    transform: scale(2);
  }
}

@keyframes fillColors {
  0%,33% {
    fill:var(--accent-color)
  }
  34%, 66% {
    fill:var(--accent-color2)
  }
  67%,100% {
    fill:var(--light-accent-color)
  }
}
@keyframes fillColors2 {
  0%,33% {
    fill:var(--accent-color)
  }
  34%, 66% {
    fill:var(--accent-color3)
  }
  67%,100% {
    fill:var(--light-accent-color)
  }
}
#root:before {
  position: fixed;
  content: ' ';
  width: 100%;
  border-top: 1vh solid white;
  display: block;
  z-index: 42;
}
#root:after {
  bottom: 0;
}
#logobox{
  width: 195px;
  height: auto;
  position: fixed;
  display: inline-block;
  z-index: 42;
}
#logobox span, #logobox #lm0 > *  {
  transition: color ease-out 0.2s, fill ease-out 0.5s;
}
#logobox:hover span {
  color: white;
}
/*
#logobox:hover #lm0 > * {
  animation-play-state: running;
}

#lm0 > * {
  animation: fillColors 1s linear 0.5s infinite;
  animation-iteration-count: infinite;
  animation-play-state: paused;
}
*/
#logobox:hover #lm0 > * {
  animation: fillColors 1s linear 0.5s infinite;
  animation-iteration-count: infinite;
}

.baseline {
  top: calc(var(--tiles-unit-multi)*5vmax);
  position: absolute;
  z-index: 42;
  left: calc(var(--tiles-unit-multi)*2.1vmax);
  /*padding: calc(var(--tiles-unit-multi)*1vmax) calc(var(--tiles-unit-multi)*1vmax) calc(var(--tiles-unit-multi)*2vmax) 0;*/
  width: auto;
}
.baseline > span {
  color: var(--main-color);
  font-size:0.85em;
}
a{
  text-decoration: none;
  color: inherit;
}
nav {
  position: fixed;
  top: 33vh;
  z-index: 42;
  left: 1vh;
  /*margin: 0.5vmax 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;*/
}
nav ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}
nav ul li{/* 
  list-style: none;
 min-height: 5vmax;
  transform: rotate(-90deg);
  transform-origin: center;
  /*min-width: 5vmax;
  margin: -0.1vmax 0 0 0vmax;
  margin: 1vmax 0 0 0vmax;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 2.5vmax;*/
}
nav ul li a {
  text-transform: lowercase;
  /*margin: 3em 0;
  border-radius: 3em;
  transition: all ease-out 0.1s;
  width: 6vmax;
  min-width: 5em;*/
  display: block;
  height: 100%;
  text-align: left;/*
  text-indent: 1vmax;
  border-left: 1px solid var(--accent-color3);
  margin-left: -1px;*/
  margin-left: -1px;
  padding: 1vmax;
  transition: all ease-in 0.1s;
}
nav ul li:hover > a, nav ul li:hover > a {
  background: black;
}

nav ul li:hover > a {
  font-size: 1.2em;
}

nav ul li > a.active:before, nav ul li:hover > a:before   {
  content: "\25CF";
  position: absolute;
  z-index: 42;
  font-size: 1em;
  margin: 0 0 0 -1.5vmax;
  color: white;
}


nav ul li:last-child > a {
  margin-bottom: -1px;
}

/*
nav ul li:first-child > a:hover, nav ul li:first-child > a:target{
  background: var(--accent-color);
}
nav ul li:nth-child(2) > a:hover, nav ul li:nth-child(2) > a:target{
  background: rgb(255, 167, 0);
}
nav ul li:nth-child(3) > a:hover, nav ul li:nth-child(3) > a:target{
  background: var(--light-accent-color);
}

nav:hover > ul li:hover > a, div.about + nav > ul > li > a#about, div.portfolio + nav > ul > li > a#portfolio, div.contact + nav > ul > li > a#contact {
  border-bottom: 0.1px solid rgb(144, 144, 144);
}

nav:hover > ul li a, nav ul li > a:target {
  border-bottom: 0.1px solid rgb(69, 69, 69);
}

nav ul li:first-child > a:hover, nav ul li:first-child > a:target{
  background: var(--accent-color3);
}
nav ul li:nth-child(2) > a:hover, nav ul li:nth-child(2) > a:target{
  background: var(--accent-color);
}
nav ul li:nth-child(3) > a:hover, nav ul li:nth-child(3) > a:target{
  background: var(--accent-color2);
}
*/
nav ul li a:hover, nav ul li button:hover, nav ul li button:focus{
  opacity: 1;
}

nav ul li:nth-child(1):hover > a::before, nav ul li:nth-child(2):hover > a::after {
  opacity: 1;
}

.baseline span:nth-child(1){
  animation: appear 1.2s forwards
}
.baseline span{
  animation: appear 1.2s forwards
}
body {
  background-color: #000;/*
  background-image: linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: calc(var(--tiles-unit-multi)*2.5vmax) calc(var(--tiles-unit-multi)*2.5vmax);
  border: inset #000001 calc(var(--tiles-unit-multi)*1vmax);
  background-position: 1vmax 1vmax;*/
  
}
section > .graph{
/*  height: calc(var(--tiles-unit-multi)*70vmax);
  position: absolute;
  width: 342%;
  bottom: 0;
  left: -121%;
  top: 1%;
}
section > .graph > .grid{
  background-image: linear-gradient(rgba(255, 255, 255, 0.1) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
  background-size: calc(var(--tiles-unit-multi)*2.5vmax) calc(var(--tiles-unit-multi)*2.5vmax);
  background-color: var(--accent-color);
  height: calc(var(--tiles-unit-multi)*100vmax);
  perspective: 1000px;
  transform: perspective(calc(var(--tiles-unit-multi)*15vmax)) rotateX(45deg) scale(1);
  box-shadow: inset calc(var(--tiles-unit-multi)*2vmax) -(calc(var(--tiles-unit-multi)*35vmax)) calc(var(--tiles-unit-multi)*4vmax) #474491;
  z-index: 1;
  display: none;*/
}
.logo {
  height: calc(var(--tiles-unit-multi)*4vmax);
  padding: calc(var(--tiles-unit-multi)*4vmax) calc(var(--tiles-unit-multi)*3.5vmax);
}
#lm_logo rect {
  animation: fillColor ease-out 0.2s 1.5s 1 forwards;
}
#lm_logo use {
  stroke: var(--main-color);
  stroke-width: calc(var(--tiles-unit-multi)*11vmax);
  stroke-dashoffset: calc(var(--tiles-unit-multi)*136vmax);
  stroke-dasharray: calc(var(--tiles-unit-multi)*136vmax);
  stroke-linecap: square;
  opacity: 0;
  fill-opacity: 0;
  animation: appear 1.2s forwards, drawLM  ease-out 0.5s 1 forwards;
}
#lm_logo path:nth-child(1) {
  fill: rgba(255, 255, 255, 0);
}
#lm_logo path:nth-child(2) {
  fill: rgba(255, 255, 255, 0);
}
.bglogo{
  width: calc(var(--tiles-unit-multi)*9vmax);
  height: calc(var(--tiles-unit-multi)*9vmax);
  position: relative  ;
  margin: calc(var(--tiles-unit-multi)*4vmax) 0 0 calc(var(--tiles-unit-multi)*5vmax);
  display: none;
}
.wrapper{
  height: calc(var(--tiles-unit-multi)*30vmax);
  margin: calc(var(--tiles-unit-multi)*5vmax) 0 0 0;
  position: relative;
  perspective: 800;
  perspective-origin: 50% calc(var(--tiles-unit-multi)*10vmax);
}
#corner-logobox {
  content: " ";
  position: absolute;
  width: 195px;
  height: calc(var(--tiles-unit-multi)*7.5vmax);
  /*animation: cube4 1s ease-out 2.5s forwards, appear 1s ease-out 2.5s forwards;*/
}
.loader {
  display: inline-block;
  width: calc(var(--tiles-unit-multi)*1.25vmax);
  height: calc(var(--tiles-unit-multi)*1.25vmax);
  position: relative;
  border: 0.25vh solid var(--main-color);
  top: 50%;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: var(--main-color);
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}
#lm {
  width: 8vmax;
  position: relative;
  margin: calc(var(--tiles-unit-multi)*(-1.5vmax)) 0 0 calc(var(--tiles-unit-multi)*(-1.7vmax));
  display: none;
}
#lm0 {
  width: 1.8vmax;
  width: calc(var(--tiles-unit-multi)*1.8vmax);
  min-width: 2.5vmax;
  margin: 2vmax;
  margin: calc(var(--tiles-unit-multi)*2vmax);
  position: absolute;
  left: -1vmax;
  z-index: 42;
  top: 0vh;
}
#lm rect {
  fill: transparent;
  width: 100%;
  height: 100%;
}
#lm path, #lm0 > * {
  fill:  rgba(255, 255, 255, 1);
}
svg.cube-svg {
  width: calc(var(--tiles-unit-multi)*6vmax);
  min-width: 90px;
  position: relative;
  padding: calc(var(--tiles-unit-multi)*2vmax);
}
#eye{
  position: absolute;
  top: 7.3vmax;
  width: 10.7vmax;
  left: 2.1vmax;
  display: none;
}
svg.cube-svg .face {
  fill: rgba(0, 0, 0, 1);
  stroke: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease;
}
svg.cube-svg .face:nth-of-type(2) {
  fill:  rgba(30, 40, 103, 1);
}
svg.cube-svg .face:nth-of-type(3) {
  fill: rgb(23, 69, 121)
}
.cube{
  transition: all 0.25s ease-out;
  transform-origin: center;
  opacity: 1;
}
#cube1.start {
  animation: appear .5s forwards, cube1 2.5s .5s forwards ;
}
#cube2.start {
  animation: appear .5s forwards, cube2 1s 1.5s forwards ;
}
#cube3.start {
  animation: appear .5s forwards, cube3 .5s 1s forwards ;
}
#cube4.start {
  animation: appear .5s forwards, cube4 1.5s 1s forwards ;
}
#cube1.start {
  animation: appear .5s forwards, cube1 2.5s .5s forwards ;
}
#cubes > *.start > .face:nth-of-type(3) {
  animation: 1s fillColors2 linear infinite;
  animation-play-state: paused;
}
#cubes > *.start > .face:nth-of-type(2) {
  animation: 1s fillColors2 linear 0.5s infinite;
  animation-play-state: paused;
}
#logobox:hover > .cube-svg #cubes > *.start > .face:nth-of-type(3), #logobox.active > .cube-svg #cubes > *.start > .face:nth-of-type(3) {
  animation-play-state: running;
}
#logobox:hover > .cube-svg #cubes > *.start > .face:nth-of-type(2), #logobox.active > .cube-svg #cubes > *.start > .face:nth-of-type(3) {
  animation-play-state: running;
}
#root{
  border-left: 1vh solid white;
  border-right: 1vh solid white;
}
#cubes:hover > *.start > .face:nth-of-type(1) {
  
}
.tile:nth-child(odd):hover, .tile:nth-child(odd)[state='visible'] {
  background: var(--accent-color);
}
.tile:nth-child(even):hover, .tile:nth-child(even)[state='visible'] {
  background: var(--accent-color3);
}
.tile:nth-child(3n):hover, .tile:nth-child(3n)[state='visible'] {
  background: var(--light-accent-color);
}
.tiles {
  position: fixed;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  border-left: 1vh solid white;
  border-right: 1vh solid white;
  padding-bottom: 10vh;
}
.tile {
  content: " ";
  height: calc(var(--tiles-unit-multi)*2.5vmax);
  width: calc(var(--tiles-unit-multi)*2.5vmax);
  display: inline-block;
  transition: 10s 0.1s background ease-out;
  opacity: 0.9;
  transition-delay: -1s;
  border: 0.01vmax solid rgba(255, 255, 255, 0.05);
}
section > .tiles:nth-child(3) {
  line-height: calc(var(--tiles-unit-multi)*0.77vmax);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
section > .graph:nth-child(4) > .tiles {
  height: calc(var(--tiles-unit-multi)*100vmax);
  perspective: 1000px;
  transform: perspective(calc(var(--tiles-unit-multi)*15vmax)) rotateX(45deg) scale(1);
  z-index: 1;
  line-height: .9vmax;
  display: none;
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 84;
  margin: 9vh 0 0vh 0;
  width: 100%;
  background: white;
}
footer span {
  color: black;
  font-size: 0.6em;
  text-align: center;
  padding: 0.25vmax 0;
}
.tab {
  color: white;
  margin-left: 10vmax;
  overflow: hidden;
  width: calc(100% - 10vmax);
}
.tab-label {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.tab-label:hover {
}

.tab-label::after {
  content: "+";
  transform-origin: bottom;
  width: 1em;
  height: 1em;
  text-align: left;
  transition: all 0.35s;
  font-family: sans;
}
#check-about{
  display: none;
}
.tab-content {
  max-height: 0;
  opacity: 0;
  padding: 0 1em;
  transition: all 0.35s;
  display: flex;
  flex-flow: column nowrap;
}

input:checked + .tab-label {
}

input:checked + .tab-label::after {
  transform: rotate(45deg);
}
input:checked ~ .tab-content {
  max-height: 100vh;
  opacity: 1;
  padding: 1em;
}

@media only screen and (max-width: 1000px) {

  .portfolioWrapper article {
    width: 50%;
    margin: 0;
  }
  .portfolioWrapper {
    margin: 0 0 0 20vmax;
    width: calc(calc(100% - 20vmax) - 15vmax);
  }
}

@media only screen and (max-width: 600px) {
  .portfolioWrapper {
    margin: 0 0 0 30%;
    width: 65%;
  }
  .portfolioWrapper article {
    width: 85%;
    margin: 0 3%;
  }
  #logobox {
    width: 100px;
  }
  .baseline > span {
    font-size: 0.5em;
  }
  .author {
    font-size: 0.5em;
  }
  .contact-card header ul {
    right: 1vmax;
    top: 2vh;
  }
  .contact-card header {
    line-height: 0.8em;
    font-size: 0.5em;
  }
  .contact-card header em {
    font-size: 0.5em;
  }
}